import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Link from 'gatsby-link'
import '../assets/fonts/fonts.css';
import { injectGlobal, css } from 'emotion';
import { connect } from 'react-redux';
import * as carsActions from '../actions/carsActions';
import * as couponActions from '../actions/couponActions';
import _ from 'lodash';
import {Spring, Transition, interpolate, animated, config} from 'react-spring/renderprops';
import SliderModal from '../components/appComponents/SliderModal';
import PlacesModal from '../components/appComponents/PlacesModal';
import DateTime from '../components/appComponents/DateTime';
import RentingChoiceButton from '../components/appComponents/RentingChoiceButton';
import Header from '../components/appComponents/Header';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import BlackOverlay from '../components/appComponents/BlackOverlay';
import PaymentRadio from '../components/appComponents/PaymentRadio';
import { updateSelectedCar, flushOrderState, updateSelectedVendor } from '../actions/orderActions';
import {
    addStop,
    addStopAtIndex,
    editStop,
    pushStopAtIndex,
    updateDestLocation,
    updatePickupLocation,
    updateCityOfStop,
    updateRideDistance,
    updateRideDuration,
    addFirstStop,
    fetchLocationByLatLong,
    updateDestCity,
    updateRideStartDate,
    updateStartTime,
    updateRideEndDate,
    updateEndTime,
    updateTripType,
    removeLastStop,
    setChauffeurDrivenToTrue,
    setSelfDrivenToTrue,
    setAirportToTrue,
    setRentalToTrue,
    flushRideState,
    updateRentalType,
    updatePickupCity,
    updateAirportType
} from '../actions/rideActions';
import { flushTripState } from '../actions/tripActions';
import { flushCarsState, fetchRating } from '../actions/carsActions';
import { flushCouponState } from '../actions/couponActions';
import Moment from 'moment';
import axios from 'axios';
import Geocode from "react-geocode";
import { CombineLatestOperator } from 'rxjs/internal/observable/combineLatest';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';
// import Autocomplete from 'react-google-autocomplete';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import OSCabIcon from '../assets/img/illustrations/outstationRentals.svg';
import SDCabIcon from '../assets/img/illustrations/selfDrivenRentals.svg';
import ATCabIcon from '../assets/img/illustrations/airportTransfer.svg';
import HRCabIcon from '../assets/img/illustrations/hourlyRentals.svg';
import HeroImage from '../assets/img/hero-bg-snow-road.png';
import NewDateTime from '../components/appComponents/NewDateTime';
import AccordionItem from '../components/appComponents/AccordionItem';
import GradientScroll from 'react-gradient-scroll-indicator';
import StarRatings from 'react-star-ratings';
import Adsense from 'react-adsense';
import AdCard from "../components/AdCard";
import CarCard from '../components/appComponents/CarCard';
import CarPopup from '../components/appComponents/CarPopup';
import Filter from '../components/appComponents/Filter';
import './dropdown.css';
import { Slide } from 'react-slideshow-image';
import { Oval } from 'react-loader-spinner'

/* global tw */
const styles = {
    header: css`
        ${tw`w-full flex justify-between items-center`};
        // height: 5.9vh;
        height: 9.5488257108vh;
        @media (min-width: 992px){
            height: 9.5488257108vh;
        } 
    `,
    listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll overflow-x-hidden scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
        -webkit-overflow-scrolling: touch;

    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-2 lg:px-6 lg:px-0 -mx-2 mx-auto scrolling-touch`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    listingRow: css`
        ${tw`flex flex-wrap w-full items-center h-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            // max-width: 75rem;
        }
    `,
    itineraryBlob: css`
        ${tw`w-full lg:w-1/2 py-0 px-2 lg:py-4 `}
        &:hover{
            cursor: pointer;
            background: rgba(240,242,245, 0.4);
            border-radius: 8.09px;
            color: purple;
        }

    `,
    fromToLocations: css`
        ${tw`lg:text-2xl text-normal font-bold opacity-70`}
        color: #222222;
        letter-spacing: -0.73px;
    `,
    blackOverlay: css`
        ${tw`fixed z-200 h-screen w-screen overflow-hidden pin-t`};
        background: rgba(0,0,0,0.6);
    `,
    choiceFilterButton: css`
        ${tw`flex items-center px-4 py-2 fixed z-50 pin-b pin-x bg-white mx-auto mb-2 md:mb-4 lg:mb-8 justify-between`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 95vw;
        height: 3.5rem;
        @media (min-width: 992px){
            width: 33vw;
            height: 5rem; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            // background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
            // -webkit-background-clip: text;
            -webkit-text-fill-color: purple;
            color: purple;
            ${tw`font-bold uppercase`};
            font-size: 0.8rem;
            @media(min-width: 992px){
                font-size: 1rem;
            }
            span{
                ${ tw`block text-sm font-medium normalcase opacity-60`};
                color: black;
                -webkit-text-fill-color: black;
                font-size: 0.6rem;
                 @media(min-width: 992px){
                    font-size: 0.7rem;
                }
            }
        }
    `,
    filterButton: css`
        ${tw`flex items-center px-4 py-2 fixed z-50 pin-b pin-x bg-white mx-auto mb-2 md:mb-4 lg:mb-8 justify-center align-center`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 15vw;
        height: 15vw;
        @media (min-width: 992px){
            width: 5vw;
            height: 5vw; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            // background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
            // -webkit-background-clip: text;
            -webkit-text-fill-color: purple;
            color: purple;
            ${tw`font-bold uppercase`};
            font-size: 0.8rem;
            @media(min-width: 992px){
                font-size: 1rem;
            }
            span{
                ${ tw`block text-sm font-medium normalcase opacity-60`};
                color: black;
                -webkit-text-fill-color: black;
                font-size: 0.6rem;
                 @media(min-width: 992px){
                    font-size: 0.7rem;
                }
            }
        }
    `,
    filterButtons: css`
        ${tw`flex text-center lg:text-left lg:px-8 mx-2 px-6 py-4 -mx-2 rounded w-1/2 bg-grey-light items-center cursor-pointer`};
    `,
    bookingForm: css`
        ${tw`items-center flex flex-wrap bg-white mt-12 relative w-full overflow-hidden mx-4`};
        border-radius: 8.09px;
        box-shadow: 0 6px 24px rgba(0,0,0,0.2);
        height: max-content;
        // max-height: initial;
        @media (min-width: 992px){
            height: 38.195302843vh;
        }

    `,
    datePicker: css`
        ${tw`list-reset`};

        li{
            ${tw`my-4 py-1 lg:py-2 px-1 lg:px-4 cursor-pointer`};
            &:hover{
                ${tw`bg-grey-light`}
            }
        }
        li.active{
            ${tw`bg-grey-dark text-white`};
        }

    `,
    autocompleteSuggestions: css`
        ${tw`list-reset w-full`};
        li{
            ${tw`py-2 px-1 my-1 text-grey-darker font-sans lg:text-lg hover:bg-grey-light`};

        }
    `,
    dateTimeScrollWrapper: css`
        ${tw`w-gr1/3 lg:text-2xl mr-6 lg:px-2 py-2 overflow-y-scroll`}; 
        height:30vh;
        -webkit-overflow-scrolling: touch;
        -webkit-mask-image: -webkit-gradient(linear, left 90%,left bottom, 0%(rgba(0,0,0,1)), 25%(rgba(0,0,0,0)),75%(rgba(0,0,0,0)), 100%(rgba(0,0,0,1)))
    `,
    itineraryLine: css`
        ${tw`w-full flex flex-wrap justify-between py-1 lg:py-1 px-6`}
        // border-bottom: 1px solid #F0F2F5;
    `,

}


const MockCards = () => (
        <React.Fragment>
            <div className={cardStyles.CarCard }></div>
            <div className={cardStyles.CarCard }></div>
            <div className={cardStyles.CarCard }></div>
        </React.Fragment>

)


const CancelToken = axios.CancelToken;
let cancel, i=0;
// old key AIzaSyAEarG8tKd2cFXWtDkAfBOrymFUCfjuFok
// zyppys portal AIzaSyDmSzYU3JlM-AjP_XL9spDTO2A7iWfilUg
Geocode.setApiKey("AIzaSyDrm-YN3aa0Lj3VMz-hD0_IExL2BYZ06qA");

const timelineLabels = (desiredStartTime, interval, period) => {
    let periodsInADay = Moment.duration(1, 'day').as(period);
    periodsInADay = periodsInADay - ( (Number(desiredStartTime.split(':')[0]) * 60) + Number(desiredStartTime.split(':')[1]) + 30);
    const timeLabels = [];
    const startTimeMoment = Moment(desiredStartTime, 'hh:mm');
    for (let i = 0; i <= periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      timeLabels.push(startTimeMoment.format('hh:mm A'));
    }
  
    return timeLabels;
  };


function convertTime12to24(time12h) {
    
    if(time12h === undefined){
        return;
    }
    let [time,modifier] = time12h.split(' ');
    // alert(time+""+modifier);
    if(modifier === "AM"){
      let [hrs,mins] = time.split(":");
      if(hrs === '12'){
        hrs = '00';
      }
      return hrs + ':' + mins + ":00";
    }
    else if(modifier === "PM"){
      let [hrs,mins] = time.split(":");
      if(Number(hrs) < 12){
        hrs = Number(hrs) + 12;
      }
      return hrs + ':' + mins + ":00";
    }
}

function convertTime24to12 (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
}

let previousState = {
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    pickupCityLat: "",
    destCityLat: "",
    rentalType:"",
    pickupLocLat:"",
    destLoc:"",
    pickupLoc:"",
    tripType:"",
}; 

class carsList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            cars: [],
            isChauffeurPricing: [],
            isChauffeurRating: [],
            isSelfPricing: [],
            isSelfRating: [],
            isChauffeur: true,
            showRentingChoices: false,
            showOverlay: false,
            isRating:false,
            text: "",
            isRoundtrip: false,
            isOverlayActive: false,
            isSliderModalActive: false,
            formEditing: false,
            isPlace: false,
            isStartDate: false,
            dateEditing: false,
            isPickupActive: false,
            isDestActive: false,
            pickupAddress: null,
            destinationAddress: null,
            pickupLocation: "",
            destLocation: '',
            pickupCity: '',
            destCity: '',
            pickupPlaces:[],
            destPlaces: [],
            endDate: Moment(),
            startDate: Moment(),
            editStartDate: false,
            editingEndDate: false,
            editingStartDate: false,
            destPlaces: [],
            startDates: [],
            startTimes: [],
            address: '',
            rentalType: this.props.ride.rentalType,
            isAirportPricing: [],
            isAirportRating: [],
            isRentalPricing: [],
            isRentalRating: [],
            showHourlyPackageOptions:false,
            rentalPackage:'',
            menuOpen:false,
            showPopup:false,
            isTermsActive:false,
            isBreakupActive:false,
            isReviewActive:false,
            distance:0,
            duration:0,
            fuelType:'both',
            vehicle:true,
            transmission:'both',
            kilometers:'both',
            isChauffeurFilter:[],
            isSelfFilter:[],
            isAirportFilter:[],
            isRentalFilter:[],
            mini:false,
            sedan:false,
            suv:false,
            distinctSelf:[],
            distinctChauffeur:[],
            withFuel: false,
            myChoizeKms: null,
            minBudgetAmt: 0,
            maxBudgetAmt: 0,
            selectedBrand:'',
            selectedDropCity: '',
            selectedPickCity: '',
            showAirportTripType: false,
            displayPrice: false
        }
        this.handleFormEdit = this.handleFormEdit.bind(this);
        // this.setStartDate = this.setStartDate.bind(this);
        // this.setEndDate = this.setEndDate.bind(this);
        this.toggleTripType = this.toggleTripType.bind(this);
        // this.onProceedPress = this.onProceedPress.bind(this);
        this.fetchVehicles = this.fetchVehicles.bind(this);
        this.handleLogoClick = this.handleLogoClick.bind(this);
        this.getDistanceBetweenStops = this.getDistanceBetweenStops.bind(this);
        this.handlePricingFilter=this.handlePricingFilter.bind(this);
        this.handleRatingFilter=this.handleRatingFilter.bind(this);
        this.handleFilter=this.handleFilter.bind(this);
        this.myChoizeKmsCalc = this.myChoizeKmsCalc.bind(this);
    }

    componentWillMount(){
        // this.useGeolocation();
        // eslint-disable-next-line
        if(typeof analytics === `undefined`){
            return;
        }
        // eslint-disable-next-line
        analytics.page('/carsList', { category: 'navigation', label: 'CarsList' });
        // 
        
        let startDates = [], endDates=[];
        let ride = this.props.ride;
        if(ride.rentalType !== 2 && ride.rentalType <=3){
            if(ride.pickup.loc === ""){
                // navigate('/');
                alert("Please enter pickup location");
                return;
            }
            else if((!ride.dest.hasOwnProperty('loc') || ride.dest.loc === '') && (ride.rentalType === 1)){
                // navigate('/');
                alert("Please enter destination location");
                return;
           }
        }
        else{
            if (!ride.pickup.hasOwnProperty('city') || ride.pickup.city === '') {
                // navigate('/');
                alert("Please choose a pickup city");
                return;
            }
        }
        

        // if (ride.rentalType === 1) {
        //     if (ride.pickup.loc === "") {
        //         // navigate('/');
        //         alert("Please enter pickup location");
        //         return;
        //     }
        // }
        // else if (ride.rentalType > 1) {
           
          
        // }
        // ReactGA.pageview("/carsList", { category: 'navigation', label: 'CarsList' });
        // ReactGA.event({
        //     category: "Ecommerce",
        //     action: "/carsList",
        //     label: "In Cars List",
        //     // value: 123
        //   });
        
        // if(ride.rentalType === 1){
        //     if(ride.dest.loc === ""){
        //         // navigate('/');
        //         alert("Please enter destination location");
        //         return;
        //     }
        // }
        if(ride.rentalType === 1  && !ride.distance>100){
        if(ride.pickup.loc !== "" || ride.dest.loc !=="") {
        if(ride.pickup.loc.includes("airport")||ride.pickup.loc.includes("Airport")||ride.dest.loc.includes("airport")||ride.dest.loc.includes("Airport")) {
            this.props.dispatch(updateRentalType(3));
            this.setState({   rentalType: 3  });

        } } }

        if(ride.rentalType===3) {
            if(ride.distance>100) {
                this.props.dispatch(updateRentalType(1));
            this.setState({   rentalType: 1  });
            }
        }

        let today = Moment();
        let minutes = 30 - (today.minute() % 30);
        let leadTime = Number(this.props.ride.leadTime);
        // let leadTime = 2;
        // let leadTime = 4;
        let minStartDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
        let startDate = Moment(ride.startDate,"DD-MM-YYYY");
        this.setState({ minStartDate, startDate, startTime: Moment(ride.startTime, "HH:mm:ss").format("hh:mm A") }, function(){
            this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
        });
        this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
        for(let i=0;i < 100; i++){
            startDates.push(Moment(minStartDate).add(i, 'd'));
        }
        let startTime = Moment(minStartDate).format("HH:mm");
        let startTimes = timelineLabels(startTime,30,'m');
        this.setState({ startDates, startTimes });

        let convertedTime = convertTime12to24(Moment(ride.startTime, "HH:mm:ss").format("hh:mm A"));
        if(convertedTime === undefined){
            return;
        }
        let [hrs,mins,secs] = convertedTime.split(':');
        let minEndDate = Moment(startDate).hours(hrs).minutes(mins).add(2,'h');
        let endDate = Moment(ride.endDate,"DD-MM-YYYY");
        this.setState({ minEndDate, endDate, endTime: Moment(ride.endTime, "HH:mm:ss").format("hh:mm A") }, function(){
            this.props.dispatch(updateEndTime(convertTime12to24(this.state.endTime)));
        });
        this.props.dispatch(updateRideEndDate(Moment(endDate).format("DD-MM-YYYY")));
        for(let i=0;i < 100; i++){
            endDates.push(Moment(minEndDate).add(i, 'd'));
        }
        let endTime = Moment(minEndDate).format("HH:mm");
        let endTimes = timelineLabels(endTime,30,'m');
        this.setState({ endDates, endTimes });
        this.setState({ pickupAddress: ride.pickup.loc, destinationAddress: ride.dest.loc, isRoundtrip: this.props.ride.measureLookupId === "100010001" ? true : false });
        if(this.props.ride.rentalType===4){
            this.setState({rentalPackage:'2 hrs, 20kms'});
        }
        else if(this.props.ride.rentalType===5){
            this.setState({rentalPackage:'4 hrs, 40kms'});
        }
        else if(this.props.ride.rentalType===6){
            this.setState({rentalPackage:'8 hrs, 80kms'});
        }
        else if(this.props.ride.rentalType===7){
            this.setState({rentalPackage:'12 hrs, 120kms'});
        }
    }

    handleFormEdit(type){
        if(this.state.rentalType === 1){
            if(this.props.isChauffeurLoading){
                return;
            }
        }
        else{
            if(this.props.isSelfDriveLoading){
                return;
            }
            if(this.props.isAirportLoading){
                return;
            }
            if(this.props.isRentalLoading){
                return;
            }
        }
        let self = this;
        if(type === 'pickup'){
            setTimeout(function(){
                
                if(self.refs.myInput) ReactDOM.findDOMNode(self.refs.myInput).focus();
            },500);
            this.setState({ 
                isOverlayActive: true, 
                isSliderModalActive: true, 
                isPickupActive: true, 
                isDestActive: false, 
                editingStartDate: false, 
                editingEndDate: false, 
                text: this.state.pickupLocation,
                address: "" ,
                showAirportTripType: false,
                showHourlyPackageOptions: false
            });
        }
        else if(type === 'dest'){
            setTimeout(function(){
                
                if(self.refs.myInput) ReactDOM.findDOMNode(self.refs.myInput).focus();
            },500);
            this.setState({ 
                isOverlayActive: true, 
                isSliderModalActive: true, 
                isDestActive: true, 
                isPickupActive: false, 
                editingStartDate: false, 
                editingEndDate: false, 
                text: this.state.destLocation,
                address: "" ,
                showAirportTripType: false,
                showHourlyPackageOptions: false
            });
        }
        else if(type === 'startDate'){
            if(this.refs.datetime){
                this.refs.datetime.handleClick();  
            }        
            this.setState({ 
                isOverlayActive: true, 
                isSliderModalActive: true, 
                editingStartDate: true, 
                editingEndDate: false, 
                isPickupActive: false, 
                isDestActive: false,
                showAirportTripType: false,
                showHourlyPackageOptions: false
            });
            
        }
        else if(type === 'endDate'){
            if(this.refs.datetime){
                this.refs.datetime.handleClick();  
              }        
            this.setState({ 
                isOverlayActive: true, 
                isSliderModalActive: true, 
                editingEndDate: true, 
                editingStartDate: false, 
                isPickupActive: false, 
                isDestActive: false,
                showAirportTripType: false,
                showHourlyPackageOptions: false
            });
        }

        else if(type === 'rentalType4'){
            // eslint-disable-next-line
            analytics.track('Rental Type 2 Hr 20Kms',{category:'clicks'});
            this.setState({rentalType:4, rentalPackage:'2 hrs, 20kms', showHourlyPackageOptions:false, isOverlayActive:false});
            this.props.dispatch(updateRentalType(4));
            this.props.dispatch(carsActions.fetchRentalVehicleDetails());
        }
        else if(type === 'rentalType5'){
            // eslint-disable-next-line
            analytics.track('Rental Type 4 Hr 40Kms',{category:'clicks'});
            this.setState({rentalType:5, rentalPackage:'4 hrs, 40kms', showHourlyPackageOptions:false, isOverlayActive:false});
            this.props.dispatch(updateRentalType(5));
            this.props.dispatch(carsActions.fetchRentalVehicleDetails());
        }
        else if(type === 'rentalType6'){
            // eslint-disable-next-line
            analytics.track('Rental Type 8 Hr 80Kms',{category:'clicks'});
            this.setState({rentalType:6, rentalPackage:'8 hrs, 80kms', showHourlyPackageOptions:false, isOverlayActive:false});
            this.props.dispatch(updateRentalType(6));
            this.props.dispatch(carsActions.fetchRentalVehicleDetails());
        }
        else if(type === 'rentalType7'){
            // eslint-disable-next-line
            analytics.track('Rental Type 12 Hr 120Kms',{category:'clicks'});
            this.setState({rentalType:7, rentalPackage:'12 hrs, 120kms', showHourlyPackageOptions:false, isOverlayActive:false});
            this.props.dispatch(updateRentalType(7));
            this.props.dispatch(carsActions.fetchRentalVehicleDetails());
        }
        else if(type === 'tripType'){
            this.toggleTripType();
            // this.setState({ isRoundtrip: !this.state.isRoundtrip });
        }
        // else if(type === 'rentalType'){
        //     // this.set
        // }
    }
   
    toggleTripType() {
        if(this.state.pickupAddress === null){
            alert('Please enter your pickup address');
            return;
        }
        if(this.state.destinationAddress === null){
            alert('Please enter your destination address');
            return;
        }
        let stops = this.props.ride.stops;
        let payLoad = stops[0];
        this.setState({ isRoundtrip: !this.state.isRoundtrip
         }, function () {
            if (this.state.isRoundtrip) {
                // eslint-disable-next-line
                analytics.track('Round Trip from carsList',{category:'clicks'});
                this.props.dispatch(addStop(payLoad));
                this.props.dispatch(updateCityOfStop({ city: payLoad.city, cityLat: payLoad.cityLat, cityLng: payLoad.cityLng }, stops.length));
                this.props.dispatch(updateTripType('Round Trip'));
                // this.getDistanceBetweenStops();
            }
            else {
                // eslint-disable-next-line
                analytics.track('Oneway from carsList',{category:'clicks'});
                if(this.props.ride.rentalType === 1){
                    this.setState({ endDate: Moment(this.state.startDate).add(12, 'h'), endTime: this.state.startTime})
                    this.props.dispatch(updateRideEndDate(Moment(this.state.startDate).add(12, 'h').format('DD-MM-YYYY')));

                }
                else {
                    this.setState({ endDate: Moment(this.state.startDate).add(1, 'd'), endTime: this.state.startTime})
                    this.props.dispatch(updateRideEndDate(Moment(this.state.startDate).add(1, 'd').format('DD-MM-YYYY')));
                }
                this.props.dispatch(updateEndTime(convertTime12to24(this.state.startTime)));
                this.props.dispatch(removeLastStop())
                this.props.dispatch(updateTripType('One Way'));
                // this.getDistanceBetweenStops();
            }
            // this.getDistanceBetweenStops();
        })
    }

    componentDidMount(){
        let ride = this.props.ride;
        if(ride.rentalType !== 2 && ride.rentalType <=3){
        if(ride.pickup.loc === ""){
            navigate("/");
            return;
        }
        else if(ride.dest.loc === "" && (ride.rentalType===1)){
            navigate("/");
            return;
        }
    }
    else{
        if (!ride.pickup.hasOwnProperty('city') || ride.pickup.city === '') {
            navigate("/");
            return;
        }
    }   
        this.fetchVehicles();
        previousState.startDate = ride.startDate;
        previousState.startTime = Moment(ride.startTime,"HH:mm:ss").format("hh:mm A");
        previousState.endDate = ride.endDate;
        previousState.endTime = Moment(ride.endTime,"HH:mm:ss").format("hh:mm A");
        previousState.pickupCityLat = ride.pickup.cityLat;
        previousState.destCityLat = ride.dest.cityLat;
        previousState.pickupLoc=ride.pickup.locLat;
        previousState.destLoc=ride.dest.locLat;
        previousState.rentalType = ride.rentalType;
        this.dynamicBudgetRange();
        this.myChoizeKmsCalc();
        this.brandsList();
    }

    brandsList = () => {
        let brandsList = [];
        let brands = [];
        if(this.props.selfDriveCars.length !== 0){
            brands = this.props.selfDriveCars.map((car) => car.sub_category.split(' ')[0].toLowerCase());
            // brands[0]='clear selection'
            console.log(brands);
            brands.map((brand, i) => {
                if(!brandsList.includes(brand)){
                    if(i===0){
                        brandsList.push('clear selection');
                        brandsList.push(brand);
                    }
                    else{
                        brandsList.push(brand);
                    }
                }
            })

            brandsList = brandsList.map( (brand,i) => {
                if(i!==0){
                    return { label: brand !== 'bmw' ? brand.charAt(0).toUpperCase() + brand.slice(1) : brand.toUpperCase(), value: brand.toLowerCase() };
                }
                else{
                    return { label: brand !== 'bmw' ? brand.charAt(0).toUpperCase() + brand.slice(1) : brand.toUpperCase(), value: brand.split(' ')[0].toLowerCase(), className: 'firstLabel' };
                }
            })
        }
        console.log(brandsList);
        this.setState({ brandsList });
    }

    dynamicBudgetRange = () => {
        let minAmt, maxAmt;
        console.log(this.state.rentalType);
        if(this.state.rentalType === 1){
            if(this.props.chauffeurCars.length !== 0){
                minAmt = this.props.chauffeurCars[0].vendors[0].discountedValue;
                maxAmt = this.props.chauffeurCars[0].vendors[0].discountedValue;
                this.props.chauffeurCars.map( car =>
                    car.vendors.map( vendor =>{
                        if(vendor.discountedValue < minAmt){
                            minAmt = vendor.discountedValue;
                        }
                        if(vendor.discountedValue > maxAmt){
                            maxAmt = vendor.discountedValue;
                        }
                    }))
                }
        }
        else if(this.state.rentalType === 2){
            if(this.props.selfDriveCars.length !== 0){
                minAmt = this.props.selfDriveCars[0].vendors[0].discountedValue;
                maxAmt = this.props.selfDriveCars[0].vendors[0].discountedValue;
                this.props.selfDriveCars.map( car =>
                    car.vendors.map( vendor =>{
                        if(vendor.discountedValue < minAmt){
                            minAmt = vendor.discountedValue;
                        }
                        if(vendor.discountedValue > maxAmt){
                            maxAmt = vendor.discountedValue;
                        }
                    }))
            }
        }
        else if(this.state.rentalType === 3){
            if(this.props.airportCars.length !== 0){
                minAmt = this.props.airportCars[0].vendors[0].discountedValue;
                maxAmt = this.props.airportCars[0].vendors[0].discountedValue;
                this.props.airportCars.map( car =>
                    car.vendors.map( vendor =>{
                        if(vendor.discountedValue < minAmt){
                            minAmt = vendor.discountedValue;
                        }
                        if(vendor.discountedValue > maxAmt){
                            maxAmt = vendor.discountedValue;
                        }
                    }))
            }
        }
        else if(this.state.rentalType === 4 || this.state.rentalType === 5 || this.state.rentalType === 6){
            if(this.props.rentalCars.length !== 0){
                minAmt = this.props.rentalCars[0].vendors[0].discountedValue;
                maxAmt = this.props.rentalCars[0].vendors[0].discountedValue;
                this.props.rentalCars.map( car =>
                    car.vendors.map( vendor =>{
                        if(vendor.discountedValue < minAmt){
                            minAmt = vendor.discountedValue;
                        }
                        if(vendor.discountedValue > maxAmt){
                            maxAmt = vendor.discountedValue;
                        }
                    }))
            }
        }
        console.log(minAmt, maxAmt);
        if(minAmt !== undefined && maxAmt !== undefined){
        console.log(minAmt, maxAmt);
        this.setState({ minBudgetAmt: minAmt, maxBudgetAmt: maxAmt})
        }
    }

    myChoizeKmsCalc = () => {
        let d1 = new Date(Moment(this.props.ride.startDate,'DD-MM-YYYY').format('MMM DD, YYYY')+' '+this.props.ride.startTime);
        let d2 = new Date(Moment(this.props.ride.endDate,'DD-MM-YYYY').format('MMM DD, YYYY')+' '+this.props.ride.endTime);
        let diff = (d2.getTime() - d1.getTime()) / 1000;
        diff /= (60 * 60);
        let myChoizeKms = ((Math.abs(Math.round(diff)))*5)+' Kms';
        console.log("MyChoize Kms "+myChoizeKms);
        this.setState({myChoizeKms})
    }

    componentDidUpdate(prevProps, prevState){
        let isChauffeurPricing = [],isChauffeurRating = [],isSelfPricing = [],isSelfRating=[],isAirportPricing= [],isAirportRating = [],isRentalPricing = [],isRentalRating = [],newObj,updatedSelfDriveCarsData = [];
        let isChauffeurFilter = [],isSelfFilter = [],isAirportFilter = [],isRentalFilter = [];
        
            if(this.state !== prevState && !(this.state.isChauffeurFilter !== prevState.isChauffeurFilter) && !(this.state.isSelfFilter !== prevState.isSelfFilter) && !(this.state.isAirportFilter !== prevState.isAirportFilter) && !(this.state.isRentalFilter !== prevState.isRentalFilter)){
        // if (this.state.vehicle !== prevState.vehicle || this.state.fuelType !== prevState.fuelType || this.state.transmission !== prevState.transmission || this.state.mini !== prevState.mini || this.state.sedan !== prevState.sedan || this.state.suv !== prevState.suv || this.state.kilometers !== prevState.kilometers) {
            if(this.state.rentalType === 1){
                isChauffeurFilter = _.cloneDeep(this.props.chauffeurCars);
                if (this.state.vehicle === false || this.state.minBudget !== prevState.minBudget || this.state.maxBudget !== prevState.maxBudget){
                if (this.state.vehicle === false) {
                    if(this.state.mini && !this.state.sedan && !this.state.suv){
                        // mini
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'MINI');
                    }
                    else if(!this.state.mini && this.state.sedan && !this.state.suv){
                        // sedan
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'SEDAN');
                    }
                    else if(!this.state.mini && !this.state.sedan && this.state.suv){
                        // suv
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'SUV');
                    }
                    else if(this.state.mini && this.state.sedan && !this.state.suv){
                        // mini sedan
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'MINI' || p.category === 'SEDAN');
                    }
                    else if(!this.state.mini && this.state.sedan && this.state.suv){
                        // sedan suv
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'SEDAN' || p.category === 'SUV');
                    }
                    else if(this.state.mini && !this.state.sedan && this.state.suv){
                        // mini suv
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'MINI' || p.category === 'SUV');
                    }
                    else if(this.state.mini && this.state.sedan && this.state.suv){
                        // all
                        isChauffeurFilter = this.props.chauffeurCars.filter( p => p.category === 'MINI' || p.category === 'SEDAN' || p.category === 'SUV');
                    }
                    console.log(isChauffeurFilter);
                }
                console.log(this.state.minBudget);
                    console.log(this.state.maxBudget);
                    let array = _.cloneDeep(isChauffeurFilter)
                    array.map((cars) =>{
                        let self = this;
                        _.remove(cars.vendors, function(vendor) {
                            return vendor.discountedValue < self.state.minBudget || vendor.discountedValue > self.state.maxBudget;
                          });
                    })
                    _.remove(array, function(car) {
                        return car.vendors.length === 0;
                      });
                    isChauffeurFilter = array;
                console.log(isChauffeurFilter);
                }
                else {
                    for (let i = 0; i < this.props.chauffeurCars.length; i++) {
                        newObj = Object.assign({}, this.props.chauffeurCars[i], { vendors: _.orderBy(this.props.chauffeurCars[i].vendors, [t => t.discountedValue], ['asc']) })
                        isChauffeurFilter.push(newObj);
                    }
                }
                this.setState({ isChauffeurFilter });
            }
           else if(this.state.rentalType === 2){
               isSelfFilter = _.cloneDeep(this.props.selfDriveCars);
               console.log(isSelfFilter);
            if (this.state.vehicle === false || this.state.fuelType !== 'both' || this.state.transmission !== 'both' || this.state.kilometers !== 'both' || this.state.minBudget !== prevState.minBudget || this.state.maxBudget !== prevState.maxBudget || this.state.selectedBrand !== 'clear') {
                if (this.state.vehicle === false) {
                    if(this.state.mini && !this.state.sedan && !this.state.suv){
                        // mini
                        isSelfFilter = isSelfFilter.filter( p => p.ref_category === 'Mini');
                    }
                    else if(!this.state.mini && this.state.sedan && !this.state.suv){
                        // sedan
                        isSelfFilter = isSelfFilter.filter( p => p.ref_category === 'Sedan');
                    }
                    else if(!this.state.mini && !this.state.sedan && this.state.suv){
                        // suv
                        isSelfFilter = isSelfFilter.filter( p => p.ref_category === 'SUV');
                    }
                    else if(this.state.mini && this.state.sedan && !this.state.suv){
                        // mini sedan
                        isSelfFilter = isSelfFilter.filter( p => p.ref_category === 'Mini' || p.ref_category === 'Sedan');
                    }
                    else if(!this.state.mini && this.state.sedan && this.state.suv){
                        // sedan suv
                        isSelfFilter = isSelfFilter.filter( p => p.ref_category === 'Sedan' || p.ref_category === 'SUV');
                    }
                    else if(this.state.mini && !this.state.sedan && this.state.suv){
                        // mini suv
                        isSelfFilter = isSelfFilter.filter( p => p.ref_category === 'Mini' || p.ref_category === 'SUV');
                    }
                    else if(this.state.mini && this.state.sedan && this.state.suv){
                        // all
                        isSelfFilter = isSelfFilter;
                    }
                }
                if (this.state.fuelType !== 'both') {
                    console.log(isSelfFilter);
                    isSelfFilter = isSelfFilter.filter(p => p.vendors[0].fuel_type.charAt(0).toUpperCase() + p.vendors[0].fuel_type.slice(1).toLowerCase() === this.state.fuelType)
                }
                if (this.state.transmission !== 'both') {
                    isSelfFilter = isSelfFilter.filter(p => p.vendors[0].gear_type.charAt(0).toUpperCase() + p.vendors[0].gear_type.slice(1).toLowerCase() === this.state.transmission)
                }
                if (this.state.kilometers !== 'both') {
                    let array = _.cloneDeep(isSelfFilter)
                        array.map((cars) =>{
                            if(this.state.kilometers === 'limited'){
                            _.remove(cars.vendors, function(vendor) {
                                return vendor.hasOwnProperty('package_kms') && vendor.package_kms.includes('unlimited');
                              });
                            }
                            else if(this.state.kilometers === 'unlimited'){
                                _.remove(cars.vendors, function(vendor) {
                                    return vendor.hasOwnProperty('package_kms') && !vendor.package_kms.includes('unlimited');
                                  });
                            }
                        })
                        isSelfFilter = array;
                    // isSelfFilter = isSelfFilter.filter(p => p.vendors[0].hasOwnProperty('package_kms') && p.vendors[0].package_kms.includes(this.state.kilometers))  
                }
                if(this.state.selectedBrand !== ''){
                    isSelfFilter = isSelfFilter.filter(p => p.sub_category.split(' ')[0].toLowerCase() === this.state.selectedBrand)
                }
                    console.log(this.state.minBudget);
                    console.log(this.state.maxBudget);
                    let array = _.cloneDeep(isSelfFilter)
                    array.map((cars) =>{
                        let self = this;
                        _.remove(cars.vendors, function(vendor) {
                            return vendor.discountedValue < self.state.minBudget || vendor.discountedValue > self.state.maxBudget;
                          });
                    })
                    _.remove(array, function(car) {
                        return car.vendors.length === 0;
                      });
                    isSelfFilter = array;
                    console.log(this.props.selfDriveCars);
                console.log(isSelfFilter);
            }
            else {
                let selfCars = [];
                for (let i = 0; i < this.props.selfDriveCars.length; i++) {
                    newObj = Object.assign({}, this.props.selfDriveCars[i], { vendors: _.orderBy(this.props.selfDriveCars[i].vendors, [t => t.discountedValue], ['asc']) })
                    selfCars.push(newObj);
                }
                isSelfFilter = selfCars;
            }
            this.setState({ isSelfFilter });
           }
           else if(this.state.rentalType === 3){
            isAirportFilter = _.cloneDeep(this.props.airportCars);
            if (this.state.vehicle === false || this.state.minBudget !== prevState.minBudget || this.state.maxBudget !== prevState.maxBudget){
            if (this.state.vehicle === false) {
                if(this.state.mini && !this.state.sedan && !this.state.suv){
                    // mini
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'MINI');
                }
                else if(!this.state.mini && this.state.sedan && !this.state.suv){
                    // sedan
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'SEDAN');
                }
                else if(!this.state.mini && !this.state.sedan && this.state.suv){
                    // suv
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'SUV');
                }
                else if(this.state.mini && this.state.sedan && !this.state.suv){
                    // mini sedan
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'MINI' || p.category === 'SEDAN');
                }
                else if(!this.state.mini && this.state.sedan && this.state.suv){
                    // sedan suv
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'SEDAN' || p.category === 'SUV');
                }
                else if(this.state.mini && !this.state.sedan && this.state.suv){
                    // mini suv
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'MINI' || p.category === 'SUV');
                }
                else if(this.state.mini && this.state.sedan && this.state.suv){
                    // all
                    isAirportFilter = this.props.airportCars.filter( p => p.category === 'MINI' || p.category === 'SEDAN' || p.category === 'SUV');
                }
                // console.log(this.state.type);
                // console.log(isAirportFilter);
            }
            // console.log(this.state.minBudget);
            // console.log(this.state.maxBudget);
            let array = _.cloneDeep(isAirportFilter)
            array.map((cars) =>{
                let self = this;
                _.remove(cars.vendors, function(vendor) {
                    return vendor.discountedValue < self.state.minBudget || vendor.discountedValue > self.state.maxBudget;
                  });
            })
            _.remove(array, function(car) {
                return car.vendors.length === 0;
              });
            isAirportFilter = array;
        console.log(isAirportFilter);
        }
            else {
                for (let i = 0; i < this.props.airportCars.length; i++) {
                    newObj = Object.assign({}, this.props.airportCars[i], { vendors: _.orderBy(this.props.airportCars[i].vendors, [t => t.discountedValue], ['asc']) })
                    isAirportFilter.push(newObj);
                }
            }
            this.setState({ isAirportFilter });
           }
           else if(this.state.rentalType >= 4){
            isRentalFilter = _.cloneDeep(this.props.rentalCars);
            if (this.state.vehicle === false || this.state.minBudget !== prevState.minBudget || this.state.maxBudget !== prevState.maxBudget){
            if (this.state.vehicle === false) {
                if(this.state.mini && !this.state.sedan && !this.state.suv){
                    // mini
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'MINI');
                }
                else if(!this.state.mini && this.state.sedan && !this.state.suv){
                    // sedan
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'SEDAN');
                }
                else if(!this.state.mini && !this.state.sedan && this.state.suv){
                    // suv
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'SUV');
                }
                else if(this.state.mini && this.state.sedan && !this.state.suv){
                    // mini sedan
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'MINI' || p.category === 'SEDAN');
                }
                else if(!this.state.mini && this.state.sedan && this.state.suv){
                    // sedan suv
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'SEDAN' || p.category === 'SUV');
                }
                else if(this.state.mini && !this.state.sedan && this.state.suv){
                    // mini suv
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'MINI' || p.category === 'SUV');
                }
                else if(this.state.mini && this.state.sedan && this.state.suv){
                    // all
                    isRentalFilter = this.props.rentalCars.filter( p => p.category === 'MINI' || p.category === 'SEDAN' || p.category === 'SUV');
                }
                // console.log(isRentalFilter);
            }
            // console.log(this.state.minBudget);
            // console.log(this.state.maxBudget);
            let array = _.cloneDeep(isRentalFilter)
            array.map((cars) =>{
                let self = this;
                _.remove(cars.vendors, function(vendor) {
                    return vendor.discountedValue < self.state.minBudget || vendor.discountedValue > self.state.maxBudget;
                  });
            })
            _.remove(array, function(car) {
                return car.vendors.length === 0;
              });
            isRentalFilter = array;
        // console.log(isRentalFilter);
        }
            else {
                for (let i = 0; i < this.props.rentalCars.length; i++) {
                    newObj = Object.assign({}, this.props.rentalCars[i], { vendors: _.orderBy(this.props.rentalCars[i].vendors, [t => t.discountedValue], ['asc']) })
                    isRentalFilter.push(newObj);
                }
            }
            this.setState({ isRentalFilter });
           }
        }

        // pricing order by chauf
        if(this.props.chauffeurCars !== prevProps.chauffeurCars){
            // for(let i=0; i<this.props.chauffeurCars.length;i++){
            //     newObj = Object.assign({},this.props.chauffeurCars[i],{ vendors: _.orderBy(this.props.chauffeurCars[i].vendors, [t => t.discountedValue], ['asc']) } )
            //     isChauffeurPricing.push(newObj);
            // }
            this.dynamicBudgetRange();

                for(let i=0; i<this.props.chauffeurCars.length;i++){
                        newObj = Object.assign({},this.props.chauffeurCars[i],{ vendors: _.orderBy(this.props.chauffeurCars[i].vendors, [t => t.discountedValue], ['asc']) } )
                        isChauffeurPricing.push(newObj);
                    }
                    
            //rating order by chauf
            for(let i=0; i<this.props.chauffeurCars.length;i++){
                newObj = Object.assign({},this.props.chauffeurCars[i],{ vendors: _.orderBy(this.props.chauffeurCars[i].vendors, [t => t.rating], ['desc']) } );
                isChauffeurRating.push(newObj);
            }

            if(this.state.isRating && this.state.rentalType === 1){
                this.setState({ cars: isChauffeurRating });
            }
            else if(!this.state.isRating && this.state.rentalType === 1){
                this.setState({ cars: isChauffeurPricing });
            }

            this.setState({  isChauffeurRating, isChauffeurPricing });
        }

        if(this.props.selfDriveCars !== prevProps.selfDriveCars){
            this.dynamicBudgetRange();
            this.brandsList();
            for(let i=0; i<this.props.selfDriveCars.length;i++){
                newObj = Object.assign({},this.props.selfDriveCars[i],{ vendors: _.orderBy(this.props.selfDriveCars[i].vendors, [t => t.discountedValue], ['asc']) } );
                isSelfPricing.push(newObj);
            }
    
            //rating order by chauf
            for(let i=0; i<this.props.selfDriveCars.length;i++){
                newObj = Object.assign({},this.props.selfDriveCars[i],{ vendors: _.orderBy(this.props.selfDriveCars[i].vendors, [t => t.rating], ['desc']) } );
                isSelfRating.push(newObj);
            }

            if(this.state.isRating && this.state.rentalType === 2){
                this.setState({ cars: isSelfRating });
            }
            else if(!this.state.isRating && this.state.rentalType === 2){
                isSelfPricing = _.orderBy(isSelfPricing, [t => t.vendors[0].discountedValue], ['asc']);
                this.setState({ cars: isSelfPricing });
            }

            this.setState({  isSelfRating, isSelfPricing });
        }

        if(this.props.airportCars !== prevProps.airportCars){
            this.dynamicBudgetRange();
            for(let i=0; i<this.props.airportCars.length;i++){
                newObj = Object.assign({},this.props.airportCars[i],{ vendors: _.orderBy(this.props.airportCars[i].vendors, [t => t.discountedValue], ['asc']) } )
                isAirportPricing.push(newObj);
                // console.log("Filter",newObj);
            }
            // this.setState({ isChauffeurPricing });

            //rating order
            for(let i=0; i<this.props.airportCars.length;i++){
                newObj = Object.assign({},this.props.airportCars[i],{ vendors: _.orderBy(this.props.airportCars[i].vendors, [t => t.rating], ['desc']) } );
                isAirportRating.push(newObj);
            }

            if(this.state.isRating && this.state.rentalType === 3){
                this.setState({ cars: isAirportRating });
            }
            else if(!this.state.isRating && this.state.rentalType === 3){
                this.setState({ cars: isAirportPricing });
            }

            this.setState({  isAirportRating, isAirportPricing });
        }
           
        
        if(this.props.rentalCars !== prevProps.rentalCars){
            this.dynamicBudgetRange();
            for(let i=0; i<this.props.rentalCars.length;i++){
                newObj = Object.assign({},this.props.rentalCars[i],{ vendors: _.orderBy(this.props.rentalCars[i].vendors, [t => t.discountedValue], ['asc']) } )
                isRentalPricing.push(newObj);
            }
            // this.setState({ isChauffeurPricing });

            //rating order
            for(let i=0; i<this.props.rentalCars.length;i++){
                newObj = Object.assign({},this.props.rentalCars[i],{ vendors: _.orderBy(this.props.rentalCars[i].vendors, [t => t.rating], ['desc']) } );
                isRentalRating.push(newObj);
            }

            if(this.state.isRating && this.state.rentalType >= 4){
                this.setState({ cars: isRentalRating });
            }
            else if(!this.state.isRating && this.state.rentalType >= 4){
                this.setState({ cars: isRentalPricing });
            }

            this.setState({  isRentalRating, isRentalPricing });
        }
         

        if(this.props.ride.pickup.cityLat !== prevProps.ride.pickup.cityLat){            
            previousState.pickupCityLat = this.props.ride.pickup.cityLat;
            if(this.props.ride.dest.loc === ""){
                setTimeout(()=>{this.fetchVehicles()},1000)
            }
            else{
                this.getDistanceBetweenStops();
            }
        }

        if(this.props.ride.dest.cityLat !== prevProps.ride.dest.cityLat){
            this.getDistanceBetweenStops();
            previousState.destCityLat = this.props.ride.dest.cityLat;
            // this.fetchVehicles();
        }

        if(this.props.ride.pickup.locLat !== prevProps.ride.pickup.locLat){            
            previousState.pickupLocLat = this.props.ride.pickup.locLat;
            if(this.props.ride.dest.loc === ""){
                setTimeout(()=>{this.fetchVehicles()},1000)
            }
            else{
                this.getDistanceBetweenStops();
            }
        }

        if(this.props.ride.dest.locLat !== prevProps.ride.dest.locLat){
            this.getDistanceBetweenStops();
            previousState.destCityLat = this.props.ride.dest.cityLat;
            // this.fetchVehicles();
        }

        if(this.props.stops.length !== prevProps.stops.length){
            this.getDistanceBetweenStops();
        }

        if(this.props.ride.distance !== prevProps.ride.distance){
            this.fetchVehicles();
        }
        // if(this.props.ride.rentalType !== prevProps.ride.rentalType){
        //     this.fetchVehicles();
        // }
        if(this.props.ride.startDate !== prevProps.ride.startDate){
            this.fetchVehicles();
            this.myChoizeKmsCalc();
        }
        if(this.props.ride.startTime !== prevProps.ride.startTime){
            this.fetchVehicles();
            this.myChoizeKmsCalc();
        }
        if(this.props.ride.endDate !== prevProps.ride.endDate){
            this.myChoizeKmsCalc();
        }
        if(this.props.ride.endTime !== prevProps.ride.endTime){
            this.myChoizeKmsCalc();
        }
        if(this.props.ride.airportType !== prevProps.ride.airportType){
            setTimeout(()=>{this.fetchVehicles()},1000)
        }
        // if(this.props.ride.dest.loc !== prevProps.ride.dest.loc){
        //     // this.getDistanceBetweenStops();
        //     this.fetchVehicles();
        // }
        // if(this.props.ride.pickup.loc !== prevProps.ride.pickup.loc){
        //     // this.getDistanceBetweenStops();
        //     this.fetchVehicles();
        // }
        // if(this.state.isRoundtrip !== prevState.isRoundtrip){
        //     // this.getDistanceBetweenStops();
        //     this.fetchVehicles();
        // }
        // if(this.props.ride.dest.city !== prevProps.ride.dest.city){
        //     this.getDistanceBetweenStops();
        //     this.fetchVehicles();
        // }
        // if(this.props.ride.pickup.city !== prevProps.ride.pickup.city){
        //     this.getDistanceBetweenStops();
        //     this.fetchVehicles();
        // }
        if(this.props.selfDriveCars !== prevProps.selfDriveCars || this.props.chauffeurCars !== prevProps.chauffeurCars){
        this.distinct();
    }
    }

    distinct = () => {
        let distinctSelf = _.uniqBy(this.props.selfDriveCars,'ref_category_id');
        let distinctChauffeur = _.uniqBy(this.props.chauffeurCars,'category_id');
        this.setState({
            distinctSelf,
            distinctChauffeur
        })
        // console.log(distinctSelf);
        // console.log(distinctChauffeur);
    }


    handleRideChange = (type) => {
        
        let ride = this.props.ride;
        if(previousState.startDate !== ride.startDate){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }

        else if(previousState.startTime !== ride.startTime){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }

        else if(previousState.endTime !== ride.endTime){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }

        else if(previousState.endDate !== ride.endDate){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }

        else if(previousState.pickupCityLat !== ride.pickup.cityLat){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }

        else if(previousState.destCityLat !== ride.dest.cityLat){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }
        else if(previousState.rentalType !== ride.rentalType){
            this.fetchVehicles();
            previousState.startDate = ride.startDate;
            previousState.startTime = ride.startTime;
            previousState.endDate = ride.endDate;
            previousState.endTime = ride.endTime;
            previousState.pickupCityLat = ride.pickup.cityLat;
            previousState.destCityLat = ride.dest.cityLat;
            previousState.rentalType=ride.rentalType;
        }
        this.setState({ isOverlayActive: false, isSliderModalActive: false, showHourlyPackageOptions:false});
    }

    fetchVehicles = () => {
        let rentalType = this.props.ride.rentalType;
        this.props.dispatch(carsActions.generateTokenForVehicles());
    }


    handleLogoClick = () => {
        this.props.dispatch(flushCarsState());
        this.props.dispatch(flushCouponState());
        this.props.dispatch(flushOrderState());
        this.props.dispatch(flushRideState());
        this.props.dispatch(flushTripState());
        // this.props.dispatch(flushUserState());
    }

    getDistanceBetweenStops() {
        
        let self = this;
        if (this.props.stops[0]===undefined && this.props.stops.length <= 2 ){
            return;
        }
        let ride = this.props.ride;
        let routepoints = [];
        let pickCity = new window.google.maps.LatLng(ride.pickup.cityLat,ride.pickup.cityLng);
        let pickLoc = new window.google.maps.LatLng(ride.pickup.locLat,ride.pickup.locLng);
       
        let request1 = {
            origin: pickCity,
            destination: pickLoc,
            waypoints: routepoints,
            travelMode: 'DRIVING'
        };

        let waypoints = [];
        let stops = this.props.stops;
        let origin = new window.google.maps.LatLng(stops[0].latitude,stops[0].longitude)
        let destination = new window.google.maps.LatLng(stops[stops.length - 1].latitude,stops[stops.length - 1].longitude)
        for(let i = 1 ; i < stops.length -1 ;i++){
            let waypoint = {location: new window.google.maps.LatLng(stops[i].latitude,stops[i].longitude),stopover: true};
            waypoints.push(waypoint);
        }

        let request = {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            travelMode: 'DRIVING'
        };

        //eslint-disable-next-line
        const DirectionsService = new window.google.maps.DirectionsService();
        
        DirectionsService.route(request1, (result, status) => {
            //eslint-disable-next-line
            console.log(result);
            if (status === "OK") {
                const route = result.routes[0];
                let distance = route.legs.reduce((carry, curr) => {
                    return carry + curr.distance.value;
                    }, 0) / 1000;
                let duration = route.legs.reduce((carry, curr) => {
                    return carry + curr.duration.value;
                    }, 0) / 3600;
                    self.setState({distance:distance,duration:duration})
            }
            else {
                console.error(`error fetching directions ${result}`);
                }
        });
       
        //eslint-disable-nexxt-line
        DirectionsService.route(request, (result, status) => {
            //eslint-disable-next-line
            console.log(result);
            if (status === "OK") {
                const route = result.routes[0];
                let distance = route.legs.reduce((carry, curr) => {
                    return carry + curr.distance.value;
                    }, 0) / 1000;
                let duration = route.legs.reduce((carry, curr) => {
                    return carry + curr.duration.value;
                    }, 0) / 3600;
                    if(stops.length>2){
                        let distance1 = self.state.distance*2;
                        let duration1 =  self.state.duration*2;
                        self.setState({
                            distance: distance1,
                            duration: duration1
                        })
                    }
                    console.log(distance+self.state.distance);
                    console.log(duration+self.state.duration);
                self.props.dispatch(updateRideDistance(parseInt(distance+self.state.distance)));
                self.props.dispatch(updateRideDuration(parseInt(duration+self.state.duration)));
            } 
            else {
                console.error(`error fetching directions ${result}`);
                }
        });
    }


    handlePricingFilter(){
        if(this.state.rentalType===1){
            this.setState({cars: this.state.isChauffeurPricing});
        }
        else if(this.state.rentalType===2){
            this.setState({cars: this.state.isSelfPricing});
        }
        else if(this.state.rentalType===3){
            this.setState({cars: this.state.isAirportPricing});
        }
        else if(this.state.rentalType>=4){
            this.setState({cars: this.state.isRentalPricing});
        }

    }

    handleRatingFilter(){
        if(this.state.rentalType===1){
            this.setState({cars: this.state.isChauffeurRating});
        }
        else if(this.state.rentalType===2){
            this.setState({cars: this.state.isSelfRating});
        }
        else if(this.state.rentalType===3){
            this.setState({cars: this.state.isAirportRating});
        }
        else if(this.state.rentalType>=4){
            this.setState({cars: this.state.isRentalRating});
        }
    }

    handleFilter(){
        if(this.state.rentalType===1){
            this.setState({cars: this.state.isChauffeurFilter});
        }
        else if(this.state.rentalType===2){
            this.setState({cars: this.state.isSelfFilter});
        }
        else if(this.state.rentalType===3){
            this.setState({cars: this.state.isAirportFilter});
        }
        else if(this.state.rentalType>=4){
            this.setState({cars: this.state.isRentalFilter});
        }
        this.setState({showRentingChoices: false, showOverlay: false});
    }

    handleStaticCities = (data, type) =>{
        if(type === 'pick'){
            this.props.dispatch(updatePickupCity({city:data.label,cityLat : data.value.latitude,cityLng:data.value.longitude, cityLeadTime : this.props.ride.leadTime.leadTime}));
        }
        else if(type === 'drop'){
            this.props.dispatch(updateDestCity({city:data.label,cityLat : data.value.latitude,cityLng:data.value.longitude}))
        }
    }

    handleAirportType = (type) => {
        this.props.dispatch(updateAirportType(type))
        this.setState({ isOverlayActive: false, showAirportTripType: false })
      }

    render(){
        let options = {
            // types: ['(regions)'],
            componentRestrictions: {country: 'in'}
          };
        
        let ride = this.props.ride;
        if (ride.rentalType !== 2 && ride.rentalType <=3) {
            if (ride.pickup.loc === "") {
                return null;
            }
        }
        if (ride.rentalType === 2 || ride.rentalType>3) {
            if (!ride.pickup.hasOwnProperty('city') || ride.pickup.city === "") {
                return null;
            }
        }
        let cards, sorry;
        let isChauffeurLoading = this.props.isChauffeurLoading;
        let isSelfDriveLoading = this.props.isSelfDriveLoading;
        let isAirportLoading = this.props.isAirportLoading;
        let isRentalLoading = this.props.isRentalLoading;
        let isChauffeurAvailable = this.props.isChauffeurAvailable;
        let isSelfDriveAvailable = this.props.isSelfDriveAvailable;
        let isAirportAvailable = this.props.isAirportAvailable;
        let isRentalAvailable = this.props.isRentalAvailable;

        if (this.state.rentalType === 1) {
            if (isChauffeurLoading && !this.state.showOverlay && !this.state.showPopup && !this.state.isOverlayActive) {
                // cards = <MockCards />
                cards = <LoadingCars />
            }
            else if (isChauffeurAvailable && this.state.cars.length > 0 && this.state.rentalType === 1) {
                cards = this.state.cars.map((car, idx) =>
                {
                    if(car.vendors.length > 0) {
                        return car.vendors.map((vendor, i) => {
                            return(
                                <CarCard
                                    key={i}
                                    id={i}
                                    type={'chauffeur'}
                                    car={car}
                                    selectedVendor={vendor}
                                    onCardPress={() => this.handleCardPress(car)}
                                    opacity={this.state.opacity}
                                    onClick={() => {
                                        this.props.dispatch(updateSelectedVendor(vendor));
                                        console.log(vendor);
                                        this.props.dispatch(fetchRating(vendor.account_id));
                                        // this.setState({ showPopup: true });
                                        // eslint-disable-next-line
                                        analytics.track('Chauffeur Driven car chosen', { category: 'cars', label: 'chaueffeur driven' });
                                        this.props.dispatch(carsActions.updateVendors(car)); this.props.dispatch(updateSelectedCar(car));; this.props.dispatch(setChauffeurDrivenToTrue())
                                        navigate("/reviewPage");
                                    }}
                                />
                        )
                        }) 
                }
                })
    
            }
            else if (!isChauffeurLoading && !isChauffeurAvailable) {
                // cards = <SorryMessage style={{width: '100%', alignItems: 'center', textAlign: 'center'}} centered emojiSize={60}/>
                cards = (
                    <div className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                        {/* <div className={css`${tw`w-2/3 text-center`}`}>
                            <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Search Illustration by undraw"/>
                            <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Search</h3>
                            <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your search, please try modifying your  dates or city</p>
                        </div> */}
                        <div className={css`${tw`w-2/3 text-center`}`}>
                            <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Sorry! All cars are Sold Out. Illustration by undraw"/>
                            <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Sorry! All cars are Sold Out.</h3>
                            <p className={css`${tw`text-grey-darker`}`}>Please call <a href="tel:+918688901902" className={css`${tw`font-bold tracking-tight no-underline opacity-75 hover:opacity-100`}`}>+91 8688 901 902</a>, our customer care team will help you arrange a Car.</p>
                        </div>
                    </div>
                );
                sorry = true;
            }
            else if (this.state.isChauffeurFilter.length<=0 || (this.state.cars.length > 0 && this.state.cars[0].vendors.length<=0) || this.state.cars.length === 0) {
                // cards = <SorryMessage style={{width: '100%', alignItems: 'center', textAlign: 'center'}} centered emojiSize={60}/>
                cards = (
                    <div className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                    <div className={css`${tw`w-2/3 text-center`}`}>
                    <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Filter Illustration by undraw"/>
                    <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Filter</h3>
                    <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your filter, please try modifying your filters</p>
                </div>
                    </div>
                );
                sorry = true;
            }
        }
    
        else if(this.state.rentalType === 2){
            if (isSelfDriveLoading && !this.state.showOverlay && !this.state.showPopup && !this.state.isOverlayActive) {
                // cards = <MockCards />
                cards = <LoadingCars />
            }
            else if (this.state.cars.length > 0 && isSelfDriveAvailable && this.state.rentalType === 2) {
                cards = this.state.cars.map((car, i) =>{
                    if(car.vendors.length>0){
                        return(
                    <CarCard
                        key={i}
                        id={i}
                        type={'self'}
                        car={car}
                        myChoizeKms={this.state.myChoizeKms}
                        onCardPress={() => this.handleCardPress(car)}
                        opacity={this.state.opacity}
                        onClick={() => { 
                            this.props.dispatch(updateSelectedVendor(car.vendors[0]));
                            this.props.dispatch(fetchRating(car.vendors[0].account_id));
                            this.setState({showPopup:true});
                            // eslint-disable-next-line
                            analytics.track('SelfDrive car chosen',{category:'cars',label:'selfdriven'});
                            this.props.dispatch(carsActions.updateVendors(car));
                            this.props.dispatch(updateSelectedCar(car));
                            this.props.dispatch(setSelfDrivenToTrue());                            
                         }}



                    />
                        )}
                        }
                )
            }
    
            else if (!isSelfDriveLoading && !isSelfDriveAvailable) {
                // cards = <SorryMessage style={{ width: '100%', alignItems: 'center', textAlign: 'center' }} centered emojiSize={60} />
                cards=(
                    <Transition
                        native
                        from={{y: 100}}
                        enter={{y: 0}}
                        leave={{y: -100}}
                        items={true}
                        >
                        {show =>
                            show &&
                           (props => <animated.div 
                                style={{transform: props.y.interpolate(y => `translate3d(0, ${y}px, 0)`)}}
                                className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                                 {/* <div className={css`${tw`w-2/3 text-center`}`}>
                                    <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Search Illustration by undraw" />
                                    <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Search</h3>
                                    <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your search, please try modifying your  dates or city</p>
                                </div> */}
                                <div className={css`${tw`w-2/3 text-center`}`}>
                                    <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Sorry! All cars are Sold Out. Illustration by undraw" />
                                    <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Sorry! All cars are Sold Out.</h3>
                                    <p className={css`${tw`text-grey-darker`}`}>Please call <a href="tel:+918688901902" className={css`${tw`font-bold tracking-tight no-underline opacity-75 hover:opacity-100`}`}>+91 8688 901 902</a>, our customer care team will help you arrange a Car.</p>
                                </div>
                            </animated.div>)
                        }

                    </Transition>
                );
                sorry = true;
            }
            else if (this.state.isSelfFilter.length<=0 || (this.state.cars.length > 0 && this.state.cars[0].vendors.length<=0) || this.state.cars.length === 0) {
                // cards = <SorryMessage style={{ width: '100%', alignItems: 'center', textAlign: 'center' }} centered emojiSize={60} />
                cards=(
                    <Transition
                        native
                        from={{y: 100}}
                        enter={{y: 0}}
                        leave={{y: -100}}
                        items={true}
                        >
                        {show =>
                            show &&
                           (props => <animated.div 
                                style={{transform: props.y.interpolate(y => `translate3d(0, ${y}px, 0)`)}}
                                className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                                <div className={css`${tw`w-2/3 text-center`}`}>
                                <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Filter Illustration by undraw"/>
                                <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Filter</h3>
                                <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your filter, please try modifying your filters</p>
                            </div>
                            </animated.div>)
                        }

                    </Transition>
                );
                sorry = true;
            }
        }

        else if(this.state.rentalType === 3) {
            if (isAirportLoading && !this.state.showOverlay && !this.state.showPopup && !this.state.isOverlayActive) {
                // cards = <MockCards />
                cards = <LoadingCars />
            }
            else if (this.state.cars.length > 0 && isAirportAvailable && this.state.rentalType === 3) {
                cards = this.state.cars.map((car, i) =>{
                    if(car.vendors.length > 0) {
                        return car.vendors.map((vendor, i) => {
                            return(
                    <CarCard
                        key={i}
                        id={i}
                        type={'chauffeur'}
                        car={car}
                        selectedVendor={vendor}
                        onCardPress={() => this.handleCardPress(car)}
                        opacity={this.state.opacity}
                        onClick={() => { 
                            this.props.dispatch(updateSelectedVendor(vendor));
                            this.props.dispatch(fetchRating(vendor.account_id));
                            // this.setState({showPopup:true});                            
                            // eslint-disable-next-line
                            analytics.track('Airport Drive car chosen',{category:'cars',label:'airport driven'});
                            this.props.dispatch(carsActions.updateVendors(car));this.props.dispatch(updateSelectedCar(car));this.props.dispatch(setAirportToTrue()) 
                            navigate("/reviewPage");
                        }}
                    />
                )})
                        }
            })
    
            }
            else if (!isAirportLoading && !isAirportAvailable) {
                // cards = <SorryMessage style={{width: '100%', alignItems: 'center', textAlign: 'center'}} centered emojiSize={60}/>
                cards = (
                    <div className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                         {/* <div className={css`${tw`w-2/3 text-center`}`}>
                            <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Search Illustration by undraw"/>
                            <h3 className={css`${tw`text-2xl text-grey-dark`}`}>{(this.props.ride.pickup.loc.includes("Airport") || this.props.ride.dest.loc.includes("Airport")) ? 'Modify Search' : 'Please include an airport'}</h3>
                            <p className={css`${tw`text-grey-darker`}`}>For Airport Transfer, either the pickup or drop location must be an airport for the same city.</p>
                        </div> */}
                        <div className={css`${tw`w-2/3 text-center`}`}>
                            <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Sorry! All cars are Sold Out. Illustration by undraw"/>
                            <h3 className={css`${tw`text-2xl text-grey-dark`}`}>{(this.props.ride.pickup.loc.includes("Airport") || this.props.ride.dest.loc.includes("Airport")) ? 'Sorry! All cars are Sold Out.' : 'Please include an airport'}</h3>
                            <p className={css`${tw`text-grey-darker`}`}>For Airport Transfer, either the pickup or drop location must be an airport for the same city.</p>
                            {/* Change Description @pike */}
                        </div>
                    </div>
                );
                sorry = true;
            }
            else if (this.state.isAirportFilter.length<=0 || (this.state.cars.length > 0 && this.state.cars[0].vendors.length<=0) || this.state.cars.length === 0) {
                // cards = <SorryMessage style={{width: '100%', alignItems: 'center', textAlign: 'center'}} centered emojiSize={60}/>
                cards = (
                    <div className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                    <div className={css`${tw`w-2/3 text-center`}`}>
                    <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Filter Illustration by undraw"/>
                    <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Filter</h3>
                    <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your filter, please try modifying your filters</p>
                </div>
                    </div>
                );
                sorry = true;
            }
        }

        else if (this.state.rentalType >= 4 && !this.state.showOverlay && !this.state.showPopup && !this.state.isOverlayActive) {
            if (isRentalLoading) {
                // cards = <MockCards />
                cards = <LoadingCars />
            }
            else if (this.state.cars.length > 0 && isRentalAvailable && this.state.rentalType >= 4) {
                cards = this.state.cars.map((car, idx) =>{
                    if(car.vendors.length>0){
                        return car.vendors.map((vendor, i) => {
                            return(
                                <CarCard
                                    key={i}
                                    id={i}
                                    type={'chauffeur'}
                                    car={car}
                                    selectedVendor={vendor}
                                    onCardPress={() => this.handleCardPress(car)}
                                    opacity={this.state.opacity}
                                    onClick={() => {
                                        this.props.dispatch(updateSelectedVendor(vendor));
                                        this.props.dispatch(fetchRating(vendor.account_id));
                                        // this.setState({ showPopup: true });
                                        // eslint-disable-next-line
                                        analytics.track('Rental Driven car chosen', { category: 'cars', label: 'rental driven' });
                                        this.props.dispatch(carsActions.updateVendors(car)); this.props.dispatch(updateSelectedCar(car));; this.props.dispatch(setRentalToTrue())
                                        navigate("/reviewPage");
                                    }}
                                />
                            )
                        })
                }
                    }
                )

            }
            else if (!isRentalLoading && !isRentalAvailable) {
                // cards = <SorryMessage style={{width: '100%', alignItems: 'center', textAlign: 'center'}} centered emojiSize={60}/>
                cards = (
                    <div className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                         {/* <div className={css`${tw`w-2/3 text-center`}`}>
                            <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Search Illustration by undraw" />
                            <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Search</h3>
                            <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your search, please try modifying your  dates or city</p>
                        </div> */}
                        <div className={css`${tw`w-2/3 text-center`}`}>
                            <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Sorry! All cars are Sold Out. Illustration by undraw" />
                            <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Sorry! All cars are Sold Out.</h3>
                            <p className={css`${tw`text-grey-darker`}`}>Please call <a href="tel:+918688901902" className={css`${tw`font-bold tracking-tight no-underline opacity-75 hover:opacity-100`}`}>+91 8688 901 902</a>, our customer care team will help you arrange a Car.</p>
                        </div>
                    </div>
                );
                sorry = true;
            }
            else if (this.state.isRentalFilter.length <= 0 || (this.state.cars.length > 0 && this.state.cars[0].vendors.length<=0) || this.state.cars.length === 0) {
                // cards = <SorryMessage style={{width: '100%', alignItems: 'center', textAlign: 'center'}} centered emojiSize={60}/>
                cards = (
                    <div className={css`${tw`w-full h-full flex text-center justify-center items-center`}`}>
                    <div className={css`${tw`w-2/3 text-center`}`}>
                    <img className={css`height: 15vh; @media (min-width: 992px){height: 35vh;}`} src={require('../assets/img/modify-search.svg')} alt="Zyppys Modify Filter Illustration by undraw" />
                    <h3 className={css`${tw`text-2xl text-grey-dark`}`}>Modify Filter</h3>
                    <p className={css`${tw`text-grey-darker`}`}>Sorry! We tried very hard but couldn't find any cars for your filter, please try modifying your filters</p>
                </div>
                    </div>
                );
                sorry = true;
            }

        }
        // let ride = this.props.ride;

        let selectedRentalIcon;
        if(this.props.ride.rentalType === 1){
            selectedRentalIcon = OSCabIcon;
        }
        else if(this.props.ride.rentalType === 2){
            selectedRentalIcon = SDCabIcon;
        }
        else if(this.props.ride.rentalType === 3 ){
            selectedRentalIcon = ATCabIcon;
        }
        else{
            selectedRentalIcon = HRCabIcon;
        }
        let self =this;
        return(
            <div className={css`${tw`max-w-screen max-h-screen overflow-hidden relative z-0 w-screen h-screen`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>
                <Helmet
                    // title='Self-Drive Car Rental, Outstation Cab, Cab to Airport'
                     title = 'Zyppys - #1 Outstation Car Rental Services in India. Book Zyppys car rentals for hassle-free journeys for family trips and outings with friends!'
                    // title = "Self-Drive Car Rental In India : Outstation Cabs | Rent A Car | Car Hire Online | Outstation Taxi Booking Service | Airport Transfers | Book Hourly Rental Car - ZYPPYS"
                    meta={[
                        { name: 'description', content: 'Hiring self-drive car is in on your fingertip  Zyppys is one of the best car rental services for outstation cabs, cab to airport and more. Zyppys is pocket and environment friendly.' },
                        // { name: 'description', content: 'Self Drive Car Rental In India - Outstation Cabs - Airport Transfer Services - Hourly Rental Car : Book best Self-drive car rentals online, hire Self drive cars without driver, Outstation cabs, Outstation taxi, Airport transfer services and Hourly rental cars at affordable prices at ZYPPYS in India.' },
                        { name: 'keywords', content: 'Zyypys, Car Rental, car rental near me, car rental app, car rental bangalore, car rental hyderabad, Roadtrip, Outstation Cabs, Road Travel, Chauffeur Driven Cars, Rental Cars, Cheap Rental Cars, Vacation Rentals, Outstation Rentals, Bike Rentals, Aha Taxis, Revv, Zoomcar, Avis, Hertz, Gozo Cabs, Savaari, Taxi Bazaar, Quick Ride, Car Pooling, Car sharing, Travel, Road travel, Airport Taxi, Self Drive Cars, Car Rentals, self drive car hire bangalore,self drive car rental bangalore ,self drive hire bangalore, self drive rental bangalore,self drive car hire hyderabad,self drive car rental hyderabad ,self drive hire hyderabad, self drive rental hyderabad,self drive hire cars ,self drive car rental ,self drive hire , self drive rental , car rental hyderabad, car hire hyderabad,car rental bangalore, car hire bangalore, Bangalore to Coorg car Rental, Bangalore to Mysore car Rental, Bangalore to Ooty car Rental, car rental, car hire, car rental near, outstation cabs, outstation car rental, outstation cabs one way, outstation cabs booking, car booking outstation, Bangalore to Chikmagalur cabs, outstation cabs hyderabad, outstation car rentals hyderabad, outstation cabs one way hyderabad, hyderabad to karimnagar cabs, bangalore to mysore cab, hyderabad to vijayawada cabs, hyderabad to warangal cabs,bangalore to coorg cab,bangalore to ooty cab, bangalore to nandi hills cab, cars, Bangalore to Pondicherry cabs, bangalore to tirupati cab, outstation cabs bangalore, outstation car rentals bangalore, cabs from hyderabad to srisailam, hyderabad to bangalore cab, Bangalore to Kodaikanal cabs, Bangalore to Chennai cabs, Bangalore to Wayanad cabs ' },
                        { name: "Viewport", content: "width=device-width" },
                        { property: "og:image", content: 'https://zyppysimages.s3.ap-south-1.amazonaws.com/zyplogo.png' },
                        { property: "og:image:width", content: "200" },
                        { property: "og:image:height", content: "200" },
                        { property: "og:type", content: "website" },
                        { property: "og:title", content: "Self-Drive Car Rental, Outstation Cab, Cab to Airport" },
                        { property: "og:description", content: "Hiring self-drive car is in on your fingertip  Zyppys is one of the best car rental services for outstation cabs, cab to airport and more. Zyppys is pocket and environment friendly." },
                        // { property: "og:url", content: "https://zyppys.com/" },
                        { property: "og:site_name", content: "Zyppys" },
                        { property: "og:locale", content: "en_US" }
                    ]}
                />  
                {/* <div className={styles.header}>
                    <div className={styles.row}>
                        <div className={css`${tw`w-1/3 h-full`}`}>
                            <img src={require('../components/SvgComponents/logos/typeface/white.svg')} alt="Zyppys"/>
                        </div>
                        <div className={css`${tw`w-1/3 h-full text-right`}`}>
                            <p className={css`${tw`font-medium text-white text-xl tracking-tight`}`}>Full Name</p>
                        </div>
                    </div>
                </div> */}
                {/* <Helmet>
                    <link rel="stylesheet" href="https://use.typekit.net/iqh3vpp.css"></link>
                </Helmet> */}
                <Header name={this.props.userName} isLoggedIn={this.props.isUserLoggedIn} onClick={this.handleLogoClick}/>
                {/* <Header name={this.props.userName} isLoggedIn={this.props.isUserLoggedIn} onClick={this.handleLogoClick} onMenuPress={()=>{this.setState({menuOpen:true})}}/> */}
                <div className={styles.listWrapper}>

                <div className={styles.row}>
                        <div className={css`${tw`w-full flex flex-wrap items-center py-8 px-2`}`}>
                           
                                <div className={css`${tw`w-full lg:w-1/2`}`}>
                                    <div className={css`${tw`w-full lg:w-1/2 flex items-center `}`}>
                                        <div className={css`${tw`w-4/5 flex items-center lg:py-4 py-0 lg:py-4 hover:bg-grey-light cursor-pointer mr-2`}`}>
                                            <p className={css`${tw`text-2xl lg:mb-2 text-grey mx-1 tracking-tight`}`}>1</p>
                                            <div className={css`${tw`w-full`}`} onClick={() => this.handleFormEdit("pickup")}>
                                                <p className={css`${tw`w-full text-lg lg:text-xl font-black uppercase tracking-tight`}`}>{ride.pickup.city}</p>
                                                {/* <p className={css`${tw`w-1/2 text-sm truncate font-medium text-grey-darker`}`}>{ride.pickup.loc}</p> */}
                                            </div>
                                        </div>
                                        {this.props.ride.rentalType !== 2 && this.props.ride.rentalType <= 3 &&
                                           <div className={css`${tw`w-4/5 flex items-center lg:py-4 py-0 lg:py-4 hover:bg-grey-light cursor-pointer mr-2`}`}>
                                                <p className={css`${tw`text-2xl lg:mb-2 text-grey mx-1 tracking-tight`}`}>2</p>
                                                <div className={css`${tw`w-full`}`} onClick={() => this.handleFormEdit("dest")}>
                                                    <p className={css`${tw`w-full text-lg lg:text-xl font-black uppercase tracking-tight`}`}>{ride.dest.city}</p>
                                                    {/* <p className={css`${tw`w-1/2 text-sm truncate font-medium text-grey-darker`}`}>{ride.dest.loc}</p> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
 

                            <div className={css`${tw`w-full lg:w-1/2 flex flex-wrap lg:justify-end items-center`}`}>

                                <div onClick={(e) => { this.handleFormEdit('startDate') }} className={css`${tw`flex items-center w-1/2 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                    {Moment(ride.startTime,"HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.startTime,"HH:mm A").format('HH').valueOf() <= 18 ? 
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />                                
                                        : 
                                     <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />                                
                                    }   
                                    <div className={css`${tw`ml-2`}`}>
                                        <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Start at</p>
                                        <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.startDate, "DD-MM-YYYY").format("DD MMM")}, { Moment(ride.startTime, "HH:mm:ss").format("hh:mm A") }</p>
                                    </div>

                                </div>
                                {(this.state.isRoundtrip || this.props.ride.rentalType === 2) &&  <div onClick={(e) => { this.handleFormEdit('endDate') }} className={css`${tw`flex items-center w-1/2 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                    {Moment(ride.endTime,"HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.endTime,"HH:mm A").format('HH').valueOf() <= 18 ?
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                    : 
                                    <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                    }
                                    <div className={css`${tw`ml-2`}`}>
                                        <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>End at</p>
                                        <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.endDate, "DD-MM-YYYY").format("DD MMM")}, { Moment(ride.endTime, "HH:mm:ss").format("hh:mm A") }</p>
                                    </div>

                                    </div>
                                }
                                {this.state.rentalType===1 &&
                                    <div onClick={(e) => { this.handleFormEdit('tripType') }} className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                       <img className={css`${tw`h-8`}`} src={this.state.isRoundtrip ? require('../components/SvgComponents/roundtrip.svg') : require('../assets/img/oneWay.png') } />
                                        <div className={css`${tw`ml-2`}`}>
                                            <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Trip Type</p>
                                            <p className={css`${tw`font-medium text-grey-darker`}`}>{this.state.isRoundtrip ? 'Roundtrip' : 'One Way'}</p>
                                        </div>
                                    </div>
                                }

                                {this.state.rentalType === 3 && 
                                    <div onClick={() => {this.setState({showAirportTripType: true, isOverlayActive: true})}}
                                        className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                        <img className={css`${tw`h-8`}`} src={require('../components/SvgComponents/roundtrip.svg')} />
                                        <div className={css`${tw`ml-2`}`}>
                                            <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Trip Type</p>
                                            <p className={css`${tw`font-medium text-grey-darker`}`}>
                                               {this.props.ride.airportType}
                                            </p>
                                        </div>

                                    </div>
                        
                                }
                               
                                {this.state.rentalType >= 4 && 
                                    <div onClick={() => {this.setState({showHourlyPackageOptions: true, isOverlayActive: true})}}
                                        className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                        {/* <img className={css`${tw`h-8`}`} src={require('../components/SvgComponents/roundtrip.svg')} /> */}
                                        <div className={css`${tw`ml-2`}`}>
                                            <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Package</p>
                                            <p className={css`${tw`font-medium text-grey-darker`}`}>
                                                {/* {this.props.ride.rentalType === 4 && '2 hrs, 20kms'} */}
                                                {this.props.ride.rentalType === 5 && '4 hrs, 40kms'}
                                                {this.props.ride.rentalType === 6 && '8 hrs, 80kms'}
                                                {this.props.ride.rentalType === 7 && '12 hrs, 120kms'}

                                            </p>
                                        </div>

                                    </div>
                        
                                }
                            </div>

                        </div>
                        
                        <div className={styles.listingRow}>
                            
                            {cards}

                        </div>

                    </div>
                </div>

                {this.state.showRentingChoices && <Filter
                    active={this.state.showRentingChoices}
                    filterClick={this.handleFilter}
                    onCloseClick={() => this.setState({ showRentingChoices: false, showOverlay: false })}
                    vehicleAllClick={() => { this.setState({ vehicle: true, mini: false, sedan: false, suv: false }) }}
                    vehicleAllActive={this.state.vehicle === true}
                    vehicleMiniCkick={() => { this.setState({ mini: !this.state.mini, vehicle: false }) }}
                    vehicleMiniActive={this.state.mini === true}
                    vehicleSedanClick={() => { this.setState({ sedan: !this.state.sedan, vehicle: false }) }}
                    vehicleSedanActive={this.state.sedan === true}
                    vehicleSuvClick={() => { this.setState({ suv: !this.state.suv, vehicle: false }) }}
                    vehicleSuvActive={this.state.suv === true}
                    rentalChoiceTwo={this.state.rentalType === 2}
                    fuelBothClick={() => { this.setState({ fuelType: 'both' }) }}
                    fuelBothActive={this.state.fuelType === 'both'}
                    fuelPetrolClick={() => { this.setState({ fuelType: 'Petrol' }) }}
                    fuelPetrolActive={this.state.fuelType === 'Petrol'}
                    fuelDieselClick={() => { this.setState({ fuelType: 'Diesel' }) }}
                    fuelDieselActive={this.state.fuelType === 'Diesel'}
                    transmissionBothClick={() => { this.setState({ transmission: 'both' }) }}
                    transmissionBothActive={this.state.transmission === 'both'}
                    transmissionManualClick={() => { this.setState({ transmission: 'Manual' }) }}
                    transmissionManualActive={this.state.transmission === 'Manual'}
                    transmissionAutoClick={() => { this.setState({ transmission: 'Automatic' }) }}
                    transmissionAutoActive={this.state.transmission === 'Automatic'}
                    kilometersBothClick={() => { this.setState({ kilometers: 'both' }) }}
                    kilometersBothActive={this.state.kilometers === 'both'}
                    kilometersUnlimitedClick={() => { this.setState({ kilometers: 'unlimited' }) }}
                    kilometersUnlimitedActive={this.state.kilometers === 'unlimited'}
                    kilometersLimitedClick={() => { this.setState({ kilometers: 'limited' }) }}
                    kilometersLimitedActive={this.state.kilometers === 'limited'}
                    budgetFilter={(event)=>{this.setState({ minBudget: event[0], maxBudget: event[1] })}}
                    minBudgetAmt={this.state.minBudgetAmt}
                    maxBudgetAmt={this.state.maxBudgetAmt}
                    brandsList={this.state.brandsList}
                    brandSelected={(event)=>{this.setState({ selectedBrand: event.value})}}
                    brandLabel={this.state.selectedBrand}
                />}


                {/* --------------OVERLAY DONT MOVE ! ---------------
                    *This is an overlay to be activated whenever a popup is activated, it's the black transparent background layer thingy, 
                    * Don't change it's position as it might affect the stacking order (Zindex order)
                */}
                {/* <Spring from={{display: 'none'}} to={{display: this.state.showOverlay ? 'block': 'none'}}>
                    {values =>
                        <div onClick={()=> this.setState({showRentingChoices: false, showOverlay: false})} className={styles.blackOverlay} style={values}></div>
                    }
                </Spring> */}
                {
                    this.props.selectedCar && this.state.showPopup && 
                            <CarPopup onClick={(vendor)=>{this.props.dispatch(updateSelectedVendor(vendor));this.props.dispatch(fetchRating(vendor.account_id)); this.setState({displayPrice: true})}} 
                            car={this.props.selectedCar} 
                            distinctSelf={this.state.distinctSelf}
                            distinctChauffeur={this.state.distinctChauffeur}
                            selectedVendor={this.props.selectedVendor}
                            selectedVendorReview={this.props.rating}
                            onReviewClick={()=>{this.setState({isReviewActive:!this.state.isReviewActive,isBreakupActive:false,isTermsActive:false})}}
                            onTermsClick={()=>{this.setState({isTermsActive:!this.state.isTermsActive,isBreakupActive:false,isReviewActive:false})}}
                            onPriceClick={()=>{this.setState({isBreakupActive:!this.state.isBreakupActive,isTermsActive:false,isReviewActive:false})}}
                            onFuelClick={()=>{this.setState({withFuel: !this.state.withFuel, displayPrice: false})}}
                            isFuel={this.state.withFuel}
                            isReviewActive={this.state.isReviewActive}
                            isTermsActive={this.state.isTermsActive}
                            isBreakupActive={this.state.isBreakupActive}
                            close={()=>{this.setState({showPopup:false, displayPrice:false, isTermsActive: false, isBreakupActive: false, withFuel: false});}}
                            rentalType={this.state.rentalType}
                            myChoizeKms={this.state.myChoizeKms}
                            displayPrice = {this.state.displayPrice}
                            onSelfClick={()=>{
                                 // eslint-disable-next-line
                            analytics.track('Selfdrive Clicked', { category: 'clicks', label: 'selfdrive choice' });
                            if (this.props.isChauffeurLoading || this.props.isSelfDriveLoading || this.props.isAirportLoading || this.props.isRentalLoading) {
                                return;
                            }
                            this.setState({
                                isChauffeur: false,
                                rentalType: 2,
                                cars: this.state.isRating ? this.state.isSelfRating : this.state.isSelfPricing,
                                showRentingChoices: false,
                                showOverlay: false,
                                showPopup:false
                            });
                            this.props.dispatch(updateRentalType(2));
                            }}
                            onChauffeurClick={()=>{ 
                                // eslint-disable-next-line
                                analytics.track('Chauffeur Clicked',{category:'clicks',label:'chauffeur choice'});
                                if(this.props.isChauffeurLoading || this.props.isSelfDriveLoading || this.props.isAirportLoading || this.props.isRentalLoading){
                                    return;
                                }
                                if(this.props.ride.dest.loc === ''){
                                    this.setState({ isOverlayActive: true, isSliderModalActive: true,isDestActive: true, isPickupActive:false, showOverlay: false,showRentingChoices: false,rentalType:1 });
                                    this.props.dispatch(updateRentalType(1));
                                }
                                else{
                                    this.setState({ 
                                        isChauffeur: true,
                                        rentalType: 1,
                                        cars: this.state.isRating ? this.state.isChauffeurRating : this.state.isChauffeurPricing,
                                        showRentingChoices: false,
                                        showOverlay: false,
                                        showPopup:false 
                                    });
                                    this.props.dispatch(updateRentalType(1));

                                }
                            }}
                             />
                   
                }
                <BlackOverlay active={this.state.showOverlay || this.state.showPopup} onClick={() => this.setState({ showRentingChoices: false, showOverlay: false, showPopup:false, displayPrice: false, isTermsActive: false, withFuel: false })}/>
                <BlackOverlay onClick={()=> {this.setState({isOverlayActive: false, isSliderModalActive: false});this.handleRideChange()}} active={this.state.isOverlayActive} >
                    {(this.state.isDestActive || this.state.isPickupActive) && 
                    <PlacesModal 
                        active={this.state.isSliderModalActive} 
                        isPickup={this.state.isPickupActive} 
                        isDest = {this.state.isDestActive}
                        onClick={()=>{this.setState({isOverlayActive: false,isSliderModalActive:false});}}
                        showHourlyPackageOptions = {this.state.showHourlyPackageOptions}
                    />
                    }
                     {(this.state.editingStartDate||this.state.editingEndDate) &&
                    <DateTime active={this.state.isSliderModalActive} isStartDate={this.state.editingStartDate} onClick={()=>{this.setState({isOverlayActive: false,isSliderModalActive:false});this.handleRideChange();}}/>
                    } 
                    {/*<NewDateTime isStartDate={this.state.editingStartDate} isEndDate={this.state.editingEndDate} ref="datetime" afterChange={()=>{}}/>*/}

                    {this.state.showHourlyPackageOptions && <SliderModal active={this.state.showHourlyPackageOptions}>
                            <div className={css`${tw`w-full flex items-center flex-wrap px-6 py-8`}`}>
                                <h3 className={css`${tw`w-full lg:px-8 font-light lg:text-3xl tracking-tight mb-2 text-grey`}`}>
                                    How long do you need the rental? <br />
                                </h3>  
                                <div className={css`${tw`w-full flex items-start lg:px-8 pb-10`}`}>
                                    <ul className={css`${tw`list-reset w-full`}`}>
                                       {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleFormEdit('rentalType4')}>2 hrs, 20kms</li> */}
                                        <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleFormEdit('rentalType5')}>4 hrs, 40kms</li>
                                        <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleFormEdit('rentalType6')}>8 hrs, 80kms</li>
                                        <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleFormEdit('rentalType7')}>12 hrs, 120kms</li>

                                    </ul>
                                </div>
                            </div>
                        </SliderModal>}
                        {this.state.showAirportTripType && <SliderModal active={this.state.showAirportTripType}>
                            <div className={css`${tw`w-full flex items-center flex-wrap px-6 py-8`}`}>
                                <h3 className={css`${tw`w-full lg:px-8 font-light lg:text-3xl tracking-tight mb-2 text-grey`}`}>
                                Choose airport pickup/dropoff <br />
                                </h3>  
                                <div className={css`${tw`w-full flex items-start lg:px-8 pb-10`}`}>
                                    <ul className={css`${tw`list-reset w-full`}`}>
                                        <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleAirportType('Airport Pickup')}>Airport Pickup</li>
                                        <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleAirportType('Airport Dropoff')}>Airport Dropoff</li>
                                    </ul>
                                </div>
                            </div>
                        </SliderModal>}
                </BlackOverlay>
                {/* OVERLAY DONT MOVE ! ---------------
                    * Overlay Ends here
                */}

                <div onClick={()=> this.setState({showRentingChoices: true, showOverlay: true})} 
                className={styles.filterButton}>
                     {/* className={styles.choiceFilterButton}>
                    <div className={css`${tw`inline-flex items-center`}`}>
                        <img className={css`${tw`inline-flex h-8 lg:mx-6 mx-4`}`} 
                            src={selectedRentalIcon}/>
                        <p>
                            {this.state.rentalType === 1 ? 'Chauffeur Driven' : (this.state.rentalType === 2 ?'Self Drive' : (this.state.rentalType === 3 ? 'Airport Transfers' : 'Hourly Rentals' ))}
                            <span>{this.state.isRating ? 'Highest Rating First': 'Lowest Price First' }</span>
                        </p>
                    </div>
                    <img className={css`${tw`inline-flex mx-4 h-2 lg:h-4 opacity-50`}`} src={require('../components/SvgComponents/angle-down.svg')}/> */}
                    <img className={css`${tw`h-4`}`} src={require('../components/SvgComponents/filter.svg')}/>

                </div>

            </div>
        )
    }

}
injectGlobal`.disabled{
    ${tw`disabled`};
`

const cardStyles = {
    CarCard: css`
        ${tw`relative flex flex-wrap items-center z-0 my-6 lg:mx-4 px-6 py-6`};
        min-height: 22rem;
        height: auto;
        border-radius: 16px;
        background: #f8f8fb;
        transition: all 0.25s ease-in-out;
        width: 100%;
        box-sizing: border-box;
        @media (min-width: 992px){
            min-height: 22rem;
            height: auto;
            width: 30%;
            &:hover{
                cursor: pointer;
                background: white;
                transform: translate3d(0,0,5px) scale(1.01);
                box-shadow: 0 15px 30px -10px rgba(0,0,0,0.2);
            }
        }
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
        }

        ${'' /* to remove the lnk colors from the cards and all 'a' links */}
        a:-webkit-any-link {
            color: inherit !important;
        }

    `,
    carImage: css`
        height: 8.5rem;
        @media(min-width: 992px){
            // transform: scale(1.1);
        }
    `,
    carName: css`
        ${tw`ml-0 mb-0`};
        opacity: 0.9;
        color: #222222;
        letter-spacing: -0.73px;
        font-size: 18px;
        @media(min-width: 992px){
            font-size: 20px;
        
        }
    `,
    reviewText: css`
        ${tw`block text-xl`};
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;
        margin-left:5px;
        vertical-align: middle;
        ${'' /* &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        } */}
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    carSpecs: css`
        opacity: 0.5;
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;
        margin-left:5px;
        vertical-align: middle;
        ${'' /* &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        } */}
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    couponText: css`
        ${tw`ml-2 my-2 opacity-40`};
        opacity: 0.4; 
        font-family: 'proxima-nova', ProximaNova-Regular; 
        letter-spacing: -0.23px; 
        font-size: 0.6rem;
        @media (min-width: 992px){
            font-size: 0.6rem;
        }
    
    `,
    serviceText: css`
        ${tw`w-10 text-right`};
        font-family: 'proxima-nova', ProximaNova-Regular; 
        font-size: 0.6rem;
        font-style: normal;
        ${'' /* font-weight: 500; */}
        font-size: 12px;
        line-height: 13px;
        letter-spacing: -0.4px;
        color: #414242;
        mix-blend-mode: normal;
        opacity: 0.5;
        margin-right:1rem;
        ${'' /* margin-left:4rem; */}
    `,
    providedText:css`
    font-family: 'proxima-nova', ProximaNova-Regular; 
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.4px;

    color: #414242;

    mix-blend-mode: normal;
    opacity: 0.5;
    `,
    lastContainer:css`
    ${tw`flex flex-row items-center justify-between text-right`};
    ${'' /* left:50%; */}
    position: absolute;
    right:10%;
    cursor:pointer;

    `,
    firstLabel: css`
        ${tw`bg-green`};
        border: 2px solid red;
        font-weight: bold;
        color: purple;
    `,
}

const slideImages = [
    // require("../assets/img/zoom-logo-transparent.png"),
    require("../assets/img/driven_logo.png"),
    require("../assets/img/mychoize_logo.png"),
    // require("../assets/img/revv_logo.png"),
    require("../assets/img/aha_logo.png"),
    require("../assets/img/savaari_logo.png"),
    require("../assets/img/swing_logo.png"),
];

const LoadingCars = () => (
    <React.Fragment>
        <div className={loadingStyles.container}>
        <p className={loadingStyles.desc}>Please wait . . .</p>
            <div className={loadingStyles.sliderContainer}>
                {/* <Slide
                    duration={1000}
                    arrows={false}
                    transitionDuration={1000}
                    pauseOnHover={false}
                >
                    {slideImages.map((item, i) => {
                        return (
                            <div key={i} className={loadingStyles.eachSlide}>
                                <div style={{ backgroundImage: `url(${item})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "80%", height: "80%" }}>
                                </div>
                            </div>)
                    })}
                </Slide> */}
                <Oval
                    visible={true}
                    height="60"
                    width="60"
                    color="#8800dd"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    secondaryColor="#8800dd"
                />
            </div>
        </div>
    </React.Fragment>
)

const loadingStyles = {
    container: css`
        ${tw`absolute`};
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.5);
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
    `,
    desc: css`
        ${tw`sm:text-lg md:text-xl lg:text-2xl`}; 
        font-style: normal;
        font-weight: 500;
        color: #000;  
        padding-left: 1rem;
        padding-right: 1rem;
    `,
    sliderContainer: css`
        ${tw`mt-4`}; 
        width: 150px;
        height: 150px;
    `,
    eachSlide: css`
        ${tw``}; 
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        width: 150px;
    `,
}

const mapStateToProps = (state) => ({
    isChauffeurLoading: state.cars.isChauffeurLoading,
    isSelfDriveLoading: state.cars.isSelfDriveLoading,
    isChauffeurAvailable: state.cars.isChauffeurAvailable,
    isSelfDriveAvailable: state.cars.isSelfDriveAvailable,
    isAirportLoading: state.cars.isAirportLoading,
    isAirportAvailable : state.cars.isAirportAvailable,
    isRentalLoading: state.cars.isRentalLoading,
    isRentalAvailable: state.cars.isRentalAvailable,
    chauffeurCars: state.cars.chauffeur,
    selfDriveCars: state.cars.selfDrive,
    airportCars: state.cars.airport,
    rentalCars: state.cars.rental,
    ride: state.ride,
    stops: state.ride.stops,
    isUserLoggedIn: state.user.isLoggedIn,
    userName: state.user.name,
    selectedCar:state.order.selectedCar,
    selectedVendor:state.order.selectedVendor,
    rating:state.cars.rating
})

export default connect(mapStateToProps)(carsList);